@mixin getResponsiveSizeRatio($height) {
  position: relative;
  width: 100% !important;
  height: 0 !important;
  padding-top: $height;
}

%responsiveSizeRatioInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//Mixin vs-button modifier
@mixin vs-button-modifier($fontColor, $backgroundColor, $borderColor, $modifier, $val, $class) {
  .vs-button-#{$class} {
    @extend %vs-button;
    background: $backgroundColor;
    color: $fontColor;
    border: 1px solid $borderColor;
    &:hover:not(:disabled) {
        background-color: call($modifier, $backgroundColor, $val);
        border-color: call($modifier, $borderColor, $val);
    }
  }
}

//Mixin vs-ripple-modifier
@mixin vs-ripple-modifier($type) {
  .ripple-effect-#{$type} {
    @extend %ripple-effect;
    background: map-get((
      primary: $vs-white,
      facebook: $vs-white,
      green: $vs-white,
      white: $vs-action-blue,
    ), $type);
  }
}
