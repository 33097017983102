/*
	- Color Constants.
	!!! Never use these directly when styling
		Instead use the purpose color variables. !!!
*/

$vs-white: #fff;
$vs-black: #000;

$vs-gray-dark: #333;
$vs-gray-dark-1: #808080;
$vs-gray-dark-2: #e7e8eb;

$vs-gray-md-1: #dbdbdb;
$vs-gray-md-2: #d9d9d9;
$vs-gray-md-3: #ccc;
$vs-gray-md-4: #666;
$vs-gray-md-5: #808080;
$vs-gray-md-6: #cbcbcb;
$vs-gray-md-8: #8b8b8b;
$vs-gray-md-9: #d7d8d9;
$vs-gray-md-10: #a5a6a7;

$vs-gray-light: #f5f5f5;
$vs-gray-light-1: #999;
$vs-gray-light-2: #f5f5f7;
$vs-gray-light-3: #f7f7f7;
$vs-gray-light-4: #f0f0f0;
$vs-gray-light-5: #f2f2f2;
$vs-gray-light-6: #F5F5F9;
$vs-gray-light-7: #8a8a8a;
/*** Brand Icons ***/
$color-facebook: #336699;
$color-twitter: #1da1f2;
$color-email: #ffce52;

// Viasport Brand Colours

$vs-yellow-light: #fef9ca;
$vs-yellow: #ffcc5e;
$vs-red: #ff4b47;
$vs-hotRed: #c70000;

$vs-green: #66cc66;

$vs-pale-blue: #D1E6F3;
$vs-action-blue: #1382c5;
$vs-highlight-blue: #27476e;
$vs-brand-blue: #18365a;


$vs-light-gray: #f5f6f7;
$vs-edge-gray: #e4e5e5;
$vs-mid-gray: #979899;
$vs-graphite: #323333;

//test-dev colors
$test1: #ff00d2;
$test2: #0f0;
$test3: #00fff0;

//NEW Brand Colours



/*
	Definintions of color purpose which use constant colors.
	Use these when using colors in the sass styling.
	Introduces variable overhead but increases effectiveness of
	future color maintenance. Add as need arises !

	Purposly bloated for future change anticipation.

	Refactor as needed, this is just a initial partition.
	Split into files for modules etc as seem fit!
*/

$color-text: $vs-graphite;
$color-text-light: $vs-mid-gray;
$color-background: $vs-white;

/*** Button ***/
$color-button-text: $vs-white;
$color-button-bg: $vs-red;

$color-button-live-text: $vs-white;

$color-button-purchase-text: $vs-white;
$color-button-purchase-bg: $vs-green;

/*** TEMPLATE ***/
$color-wrapper-bg: $vs-white;
$color-content-area-border: $vs-edge-gray;

/*** NAVIGATION ***/
/*$color-navbar: $vs-brand-blue;
$color-nav-links: $vs-white;
$color-nav-links-hover: $vs-brand-blue;
$color-nav-link-active: $vs-action-blue;
$color-nav-secondary: $vs-highlight-blue;
$color-nav-secondary-links: $vs-white;
$color-nav-mobile-bg: $vs-brand-blue;
$color-nav-mobile-secondary: $vs-highlight-blue;
$color-nav-mobile-active: $vs-action-blue;
$color-nav-mobile-text: $vs-white;
$color-nav-popup: $vs-white;
$color-nav-popup-links: $vs-graphite;
$color-nav-popup-secondary: $vs-brand-blue;
$color-nav-popup-secondary-links: $vs-white;
$color-nav-popup-secondary-border: $vs-edge-gray;
$color-subnav: $vs-light-gray;
$color-subnav-links: $vs-graphite;
$color-subnav-links-hover: $vs-gray-light-5;
$color-subnav-active: $vs-action-blue;
$color-nav-main-toggle-button: $vs-white;
$color-subnav-toggle-button: $vs-black;
$color-nav-menu-toggler: $vs-white;
$color-navbar-button: $vs-white;
$color-dropdown-link-active-bg: $vs-action-blue;

*/
/*** TV3 SPORT NAVIGATION ***/

$color-navbar: $vs-white;
$color-nav-links: $vs-black;
$color-nav-links-hover: $vs-gray-light;
$color-nav-link-active: $vs-gray-light;
$color-nav-secondary: $vs-gray-light-6;
$color-nav-secondary-links: $vs-black;

$color-nav-mobile-bg: $vs-white;
$color-nav-mobile-secondary: $vs-gray-light-6;
$color-nav-mobile-active: $vs-gray-light;
$color-nav-mobile-text: $vs-black;

$color-nav-popup: $vs-white;
$color-nav-popup-links: $vs-graphite;
$color-nav-popup-secondary: $vs-brand-blue;
$color-nav-popup-secondary-links: $vs-white;
$color-nav-popup-secondary-border: $vs-edge-gray;
$color-subnav: $vs-light-gray;
$color-subnav-links: $vs-graphite;
$color-subnav-links-hover: $vs-gray-light-5;
$color-subnav-active: $vs-gray-light;
$color-nav-main-toggle-button: vs-gray-light;
$color-subnav-toggle-button: vs-gray-light;
//$color-nav-menu-toggler: $vs-white;
$color-nav-menu-toggler: $vs-black;
$color-navbar-button: $vs-white;
$color-dropdown-link-active-bg: $vs-gray-light-6;

/*** USER AREA ***/
$color-user-area-border: $vs-edge-gray;
$color-user-area-avatar: $vs-edge-gray;
$color-user-area-text: $vs-gray-dark-1;

/*** CONTENT AREA ***/
$color-content-area-border: $vs-edge-gray;

/*** FEED CONTAINERS ***/
$color-feed-container-big-preamble: $vs-graphite;
$color-feed-container-small-heading: $vs-graphite;
$color-feed-container-simple-heading: $vs-graphite;
$color-feed-container-social-button-text: $vs-white;
$color-feed-container-date: $vs-gray-light-1;
$color-feed-container-list-border: $vs-edge-gray;
$color-feed-container-list-bg: $vs-gray-light-3;
$color-feed-container-play-button: $vs-white;
$color-tags-text: $vs-action-blue;
$color-feed-container-banner-bg: $vs-black;
$color-feed-container-banner-border: $vs-white;
$color-feed-container-banner-text: $vs-white;
$color-feed-item-indicator: $vs-action-blue;
//$color-feed-item-indicator: $vs-hotRed;
/*** ARTICLES ***/
$color-article-bottom-border: $vs-edge-gray;
$color-article-byline-link: $vs-action-blue;
$color-related-news-bg: $vs-light-gray;
$color-article-media-description: $vs-light-gray;
$color-related-news-border: $vs-edge-gray;
$color-article-editor: $vs-mid-gray;
$color-article-ad-border: $vs-edge-gray;

/*** STORY NAV ***/
$color-story-nav-bg: $vs-white;
$color-story-nav-progress-bar: $vs-gray-md-2;
$color-story-nav-link: $vs-graphite;
$color-story-nav-border: $vs-edge-gray;
$color-story-nav-active: $vs-action-blue;

/*** FOOTER ***/
$color-footer-active-mark-bg: $vs-action-blue;
$color-footer-bg: $vs-gray-dark;
$color-footer-separator: $vs-gray-md-5;
$color-footer-text: $vs-white;

/*** ViaplayLiveFeed [vlf] ***/
$color-vlf-text: $vs-graphite;
$color-vlf-module-bg: $vs-light-gray;
$color-vlf-borders: $vs-edge-gray;

/*** ViasportLiveFeed [vslf] ***/
$color-vslf-header-bg: $vs-brand-blue;

/*** Live stream listing [lsl] ***/
$color-lsl-borders: $vs-edge-gray;
$color-lsl-list-item-bg1: $vs-white;
$color-lsl-list-item-bg2: $vs-light-gray;
$color-lsl-date-bg: $vs-mid-gray;
$color-lsl-date-text: $vs-white;
$color-lsl-league-text: $vs-gray-light-1;

/*** Video feed ***/
$color-video-feed-heading: $vs-graphite;

/*** LOGIN && SIGN UP MODALS ***/
$color-modal-border: $vs-edge-gray;
$color-modal-text: $vs-graphite;
$color-modal-forgot-bg: $vs-gray-light-4;
$color-modal-forgot-link: $vs-action-blue;
$color-modal-bg: $vs-white;
$color-login-border-text-bg: $vs-white;
$color-login-border-text: $vs-graphite;
$color-input-border: $vs-edge-gray;
$color-input-border-focus: $vs-brand-blue;

/*** Video Index ***/
$color-video-index-date-item-bg: $vs-white;
$color-video-index-date-item-active-bg: $vs-action-blue;
$color-video-index-date-item-border: $vs-brand-blue;
$color-video-index-date-item-disabled-border: $vs-edge-gray;
$color-video-index-date-item-active-text: $vs-white;
$color-video-index-date-item-disabled-text: $vs-edge-gray;
$color-video-index-date-item-default-text: $vs-graphite;
$color-video-index-group-bg: $vs-light-gray;
$color-video-index-group-border: $vs-edge-gray;
$color-video-index-date-separator: $vs-gray-md-3;
$color-date-list-fixed-bg: $vs-white;
$color-date-list-fixed-border: $vs-edge-gray;
$color-video-index-event-title: $vs-graphite;
$color-video-index-event-hover-bg: $vs-white;
$color-video-index-event-clips-text: $vs-mid-gray;
$color-video-index-month-indicator-bg: $vs-gray-md-3;
$color-video-index-month-indicator-text: $vs-white;

/*** Share buttons ***/
$color-share-icon: $vs-white;
$color-share-fb-bg: $color-facebook;
$color-share-tw-bg: $color-twitter;
$color-share-mail-bg: $vs-yellow;
$color-share-bg: $vs-light-gray;
$color-share-border: $vs-edge-gray;
$color-share-total: $vs-mid-gray;

/*** Thumbnail Share ***/
$color-thumbnail-text: $vs-white;
$color-thumbnail-bg: $vs-black;

/*** Follow modal ***/
$color-follow-icon: $vs-white;
$color-follow-fb-bg: $color-facebook;
$color-follow-tw-bg: $color-twitter;
$color-follow-mail-bg: $color-email;

/*** Higlighted box ***/
$color-highlight-box-bg: $vs-light-gray;
$color-highlight-box-border: $vs-edge-gray;
$color-highlight-box-heading: $vs-graphite;

/*** League box ***/
$color-league-box-text: $vs-graphite;

/*** Team list ***/
$color-team-list-text: $vs-graphite;

/*** Fact box ***/
$color-fact-box-border: $vs-edge-gray;
$color-fact-box-bg: $vs-light-gray;

/*** TV-Guide ***/
$color-tv-guide-bg: $vs-white;
$color-tv-guide-marker: $vs-light-gray;
$color-tv-guide-marker2: $vs-action-blue;
$color-tv-guide-timewrapper: $vs-gray-md-9;
$color-tv-guide-now: $vs-action-blue;
$color-tv-guide-border: $vs-edge-gray;
$color-tv-guide-day-navigation: $vs-gray-light;
$color-tv-guide-time: $vs-gray-md-10;
$color-tv-guide-text: $vs-graphite;
$color-tv-guide-active: $vs-action-blue;
$color-tv-guide-slider: $vs-edge-gray;
$color-tv-guide-sidebar-bg: $vs-gray-light;
$color-tv-guide-button: $vs-brand-blue;
$color-tv-guide-button-text: $vs-white;
$color-tv-guide-link: $vs-action-blue;
$color-tv-guide-sidebar-time: $vs-mid-gray;
$color-tv-guide-controls: $vs-graphite;
$color-tv-guide-event-live-bg: $vs-red;
$color-tv-guide-event-live-text: $vs-white;
$color-tv-guide-progress-bar: $vs-action-blue;
$color-tv-guide-progress-bar-bg: $vs-gray-md-6;
$color-tv-guide-channel-bg: $vs-gray-light;
$color-tv-guide-channel-item-disabled: lighten($vs-edge-gray, 7%);
$color-tv-guide-search-input: $vs-white;

/*** Search ***/
$color-search-button-text: $vs-white;
$color-search-border: $vs-edge-gray;
$color-search-background: $vs-light-gray;
$color-search-button: $vs-action-blue;
//$color-search-button-text: $vs-white;
$color-search-reset-button: $vs-gray-md-2;
$color-search-button-text: $vs-black;
/*** Ads ***/
//$color-ticker-ad-bg: $vs-brand-blue;
$color-ticker-ad-bg: $vs-white;
/*** Videoplayer ***/
$color-video-player-message: $vs-white;
$color-video-player-message-background: $vs-black;

/*** Cookie message ***/
$color-cookie-close: $vs-action-blue;
$color-cookie-message-bg: $vs-pale-blue;

/*** Poll ***/
$color-poll-border: $vs-edge-gray;
$color-poll-bg: $vs-light-gray;
$color-poll-choice-bg: $vs-action-blue;
$color-poll-choice-radio-bg: $vs-white;
$color-poll-choice-bar-bg: $vs-mid-gray;

/*** Notifications ***/
$color-notification-border: $vs-white;
$color-notification-bg: $vs-pale-blue;
$color-notification-warning-bg: $vs-yellow-light;

/*** Slider message ***/
$color-slider-button-background: $vs-light-gray;
$color-slider-button-icon: $vs-black;
$color-slider-count: $vs-white;
$color-slider-count-shadow: $vs-black;
