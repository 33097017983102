.embed-player .image,
.video-player {
  @include getResponsiveSizeRatio(56.25%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  padding-top: 56.25%;

  img,
  .avodp {
    @extend %responsiveSizeRatioInner;
  }
}

.video-wrapper {
  .loader-container {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    
    .loader {
      height: auto;
    }
  }
}

.video-player-message {
  position: absolute;
  z-index: 100;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  color: $color-video-player-message;
  background: $color-video-player-message-background;
}

.video-wrapper .play-overlay,
.video-wrapper .avodp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video-player-adblock {
  font-size: 1rem;
  line-height: 1.4;
  padding: 55px;
}